import * as React from "react";
import { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import Button from "@mui/material/Button";
import logo from "../../ProjectImages/logo.png";
import "./header.css";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const navItems = ["Home", "About", "Services", "Career", "Contact"];

function Header(props) {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [navBar, setnavBar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setnavBar(true);
    } else {
      setnavBar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemText
                  primary={item}
                  sx={{
                    textTransform: "none",
                  }}
                />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        // className={navBar ? "appBar active" : "appBar"}
        sx={{
          padding: { sx: "none", lg: "15px 100px 15px 100px" },
          backgroundColor: navBar ? "#058037" : "#058037",
          position: "fixed",
          top: 0,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Link to="/">
              <Box
                component="img"
                src={logo}
                sx={{
                  height: { xs: "45px", sm: "45px", md: "45px", lg: "60px" },
                  // border: "1px solid red",
                  // width: { xs: "24px", sm: "24px", md: "24px", lg: "50px" },
                }}
              ></Box>
            </Link>
            {/* <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                variant="h4"
                sx={{
                  my: 2,
                  marginLeft: "10px",
                  fontWeight: "bold",
                  fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "30px" },
                  textDecoration: "none",
                }}
              >
                PAADYAMEE
              </Typography>
            </Link> */}
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Link
                key={item}
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
              >
                <Button
                  key={item}
                  className="navButton"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    fontSize: "23px",
                    width: { md: "100px", lg: "150px" },
                  }}
                >
                  {item}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
// }

Header.propTypes = {};

export default Header;
